import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import React from "react";
import Footer from "./landing-footer";
import Navigation from "./landing-navigation";

const Layout = ({ children, isDefault = true, navBg = false }) => {
	return (
		<>
			<Navigation isDefault={isDefault} navBg={navBg} />
			<CookieBanner url="/policy/privacy-policy" />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
