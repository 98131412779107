import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ContactForm from "../components/landing-contact-form";
import bgImage from "../images/why-choose-bg.png";
import { InlineWidget } from "react-calendly";
import ContactFormCalendly from "./landing-contact-form-calendly";

const LandingGetInTouch = ({
	heading,
	tagline,
	content,
	formButtonText,
	formTitle,
	slug,
	redirect,
	calendly,
}) => {
	return (
		<section
			id="form"
			className="bg-blue-bg py-5 py-md-8 get-in-touch"
			style={sectionStyle()}
		>
			<Container>
				<Row className="align-items-lg-center justify-content-center">
					<Col lg={8} className="mb-4 mb-lg-0 text-center">
						<span className="text-white">{tagline}</span>
						<h2 className="my-auto text-white pb-2">{heading}</h2>
						<div
							dangerouslySetInnerHTML={{
								__html: content,
							}}
							className="fw-light text-white text-center"
						></div>
					</Col>
				</Row>
				{calendly !== true && (
					<Row className="align-items-lg-center justify-content-lg-center mt-4">
						<Col lg={7} xl={5}>
							<div className="bg-white p-4 m-lg-4 rounded-4">
								<ContactForm
									formButtonText={formButtonText}
									formTitle={formTitle}
									slug={slug}
									redirect={redirect}
								/>
							</div>
						</Col>
					</Row>
				)}
				{calendly === true && (
					<ContactFormCalendly slug={slug} formTitle={formTitle} />
				)}
			</Container>
		</section>
	);
};
export const sectionStyle = () => ({
	background: `url(${bgImage}) no-repeat`,
	backgroundPosition: "top right",
	backgroundSize: "40%",
});

export default LandingGetInTouch;
