import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import { MdPhone } from "react-icons/md";

const Navigation = ({ isDefault }) => {
  return (
    <>
      <Navbar
        expand="xl"
        className={`${"default-nav border-bottom border-primary position-relative"}`}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.30)",
          zIndex: 3,
        }}
      >
        <Container style={{ zIndex: 2 }} className="position-relative">
          <Navbar.Brand as={Link} to="/" className="p-0">
            <StaticImage
              src={"../images/brand-logo.png"}
              alt="Netural Carbone Zone"
              height={62}
              layout="fixed"
            />
          </Navbar.Brand>
          
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
