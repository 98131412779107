import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaLinkedin, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-pricing-card">
      <Container>
        <div className="py-3 d-flex w-full justify-content-center ">
          <StaticImage
            src={"../images/brand-logo.png"}
            alt="Netural Carbone Zone"
            style={{ maxWidth: "72px" }}
            height={62}
            layout="fixed"
          />
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
