import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import HeroBannerSmall from "../components/hero-banner-landing";
import Layout from "../components/landing-layout";
import OurServices from "../components/our-sevices";
import TrustPilotReviews from "../components/trust-pilot-reviews";
import TrustedBy from "../components/trusted-by";
import TwoColumnImageContent from "../components/two-column-image-content";
import LandingGetInTouch from "../components/landing-get-in-touch";
import { checkPropertiesForNull } from "../utils";
import OtherTwoColumn from "../components/other-two-column";

const GoogleAdsPostTemplate = ({ data }) => {
	const {
		post: { slug, title, seoFields, landingPagePostFields },
		site,
	} = data;

	const {
		landingBannerSection,
		landingTrustedBy,
		landingTwoColumn,
		landingTwoColumnImage,
		landingTwoColumn2,
		landingTwoColumn3,
		landingOurServices,
		landingTrustpilotReviews,
		landingGetInTouch,
	} = landingPagePostFields;

	const siteUrl = site.siteMetadata.siteUrl;

	return (
		<>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				nofollow
				noindex
			/>
			<Layout>
				{!checkPropertiesForNull(landingBannerSection, ["heading"]) && (
					<HeroBannerSmall
						hideForm={landingBannerSection.hideForm}
						redirect={landingPagePostFields.redirectOnSubmit}
						slug={slug}
						heading={landingBannerSection.heading}
						content={landingBannerSection.content}
						bgImage={
							landingBannerSection.background.node?.localFile?.childImageSharp
								?.gatsbyImageData
						}
						formTitle={landingBannerSection?.formTitle}
						formButtonText={landingBannerSection?.formButtonText}
						btnCta1={landingBannerSection?.cta}
					/>
				)}
				{!checkPropertiesForNull(landingTrustedBy, ["trustedByHeading"]) && (
					<TrustedBy
						heading={landingTrustedBy?.trustedByHeading}
						logos={landingTrustedBy?.selectTrustedByLogos?.nodes}
					/>
				)}
				{!checkPropertiesForNull(landingTwoColumn, ["twoColumnHeading"]) && (
					<div className="image__content__wrapper">
						<TwoColumnImageContent
							heading={landingTwoColumn?.twoColumnHeading}
							content={landingTwoColumn?.twoColumnContent}
							btnCta1={landingTwoColumn?.twoColumnCta1}
							btnCta2={landingTwoColumn?.twoColumnCta2}
							image={landingTwoColumn?.twoColumnImage?.node}
							videoUrl={landingTwoColumn?.twoColumnVideoYoutube}
							video={landingTwoColumn?.twoColumnVideoFile?.node}
							bgColor={landingTwoColumn?.twoColumnImageBackgroundColor}
							textColor={"#E8BC38"}
							flip={landingTwoColumn?.flip}
							slug={slug}
						/>
					</div>
				)}
				{!checkPropertiesForNull(landingTwoColumnImage, ["twoColumnImage"]) && (
					<div className="image__content__wrapper">
						<TwoColumnImageContent
							heading={landingTwoColumnImage?.twoColumnHeading}
							content={landingTwoColumnImage?.twoColumnContent}
							image={landingTwoColumnImage?.twoColumnImage?.node}
							videoUrl={landingTwoColumnImage?.twoColumnVideoYoutube}
							video={landingTwoColumnImage?.twoColumnVideoFile?.node}
							mainBgColor={landingTwoColumnImage?.twoColumnImageBackgroundColor}
							textColor={"#fff"}
							bodyTextColor={"#fff"}
							borderColor={"#fff"}
							bgImage={
								landingTwoColumnImage.twoColumnBackgroundImage?.node?.sourceUrl
							}
							flip={landingTwoColumnImage?.flip}
							btnCta1={landingTwoColumnImage?.twoColumnCta1}
						/>
					</div>
				)}
				{!checkPropertiesForNull(landingOurServices, ["twoColumnContent"]) && (
					<div className="image__content__wrapper">
						<OtherTwoColumn
							heading={landingTwoColumn2?.twoColumnHeading}
							content={landingTwoColumn2?.twoColumnContent}
							btnCta1={landingTwoColumn2?.twoColumnCta1}
							btnCta2={landingTwoColumn2?.twoColumnCta2}
							image={landingTwoColumn2?.twoColumnImage?.node}
							videoUrl={landingTwoColumn2?.twoColumnVideoYoutube}
							video={landingTwoColumn2?.twoColumnVideoFile?.node}
							bgColor={landingTwoColumn2?.twoColumnImageBackgroundColor}
							textColor={"#E8BC38"}
							flip={landingTwoColumn2?.flip}
							contentLogos={landingTwoColumn2?.twoColumnContentLogos}
						/>
					</div>
				)}
				{!checkPropertiesForNull(landingOurServices, [
					"ourServicesHeading",
				]) && (
					<div className="landing-service">
						<OurServices
							heading={landingOurServices?.ourServicesHeading}
							ctaButton={landingOurServices?.ourServicesCta}
							services={landingOurServices?.selectOurServices?.nodes}
							bgImage={
								landingOurServices?.backgroundImage.node?.localFile
									?.childImageSharp.gatsbyImageData
							}
							centerContent={true}
							smallPadding={true}
						/>
					</div>
				)}
				{!checkPropertiesForNull(landingTwoColumn3, ["twoColumnImage"]) && (
					<div className="image__content__wrapper">
						<TwoColumnImageContent
							heading={landingTwoColumn3?.twoColumnHeading}
							content={landingTwoColumn3?.twoColumnContent}
							btnCta1={landingTwoColumn3?.twoColumnCta1}
							btnCta2={landingTwoColumn3?.twoColumnCta2}
							image={landingTwoColumn3?.twoColumnImage?.node}
							videoUrl={landingTwoColumn3?.twoColumnVideoYoutube}
							video={landingTwoColumn3?.twoColumnVideoFile?.node}
							bgColor={landingTwoColumn3?.twoColumnImageBackgroundColor}
							textColor={"#E8BC38"}
							flip={landingTwoColumn3?.flip}
						/>
					</div>
				)}
				{!checkPropertiesForNull(landingTrustpilotReviews, [
					"trustpilotHeading",
				]) && (
					<TrustPilotReviews
						heading={landingTrustpilotReviews?.trustpilotHeading}
						logo={landingTrustpilotReviews?.trustpilotLogo}
						reviews={landingTrustpilotReviews?.selectTrustpilotReview}
						title={landingTrustpilotReviews?.trustpilotTitle}
						tagline={landingTrustpilotReviews?.trustpilotTagline}
						btnCta1={landingTrustpilotReviews?.trustpilotCta}
						primaryButton={true}
					/>
				)}
				{!checkPropertiesForNull(landingGetInTouch, ["getInTouchHeading"]) && (
					<LandingGetInTouch
						calendly={landingGetInTouch?.calendly}
						redirect={landingPagePostFields.redirectOnSubmit}
						heading={landingGetInTouch?.getInTouchHeading}
						tagline={landingGetInTouch?.getInTouchTagline}
						content={landingGetInTouch?.getInTouchContent}
						formTitle={landingGetInTouch?.getInTouchFormHeading}
						formButtonText={landingGetInTouch?.getInTouchFormButtonLabel}
						slug={slug}
					/>
				)}
			</Layout>
		</>
	);
};

export default GoogleAdsPostTemplate;

export const pageQuery = graphql`
	query LandingPagePostById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		post: wpLandingPage(id: { eq: $id }) {
			slug
			title
			seoFields {
				title
				opengraphTitle
				opengraphDescription
				metaDescription
				localBusinessSchema
				image {
					node {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
							publicURL
						}
					}
				}
			}
			landingPagePostFields {
				redirectOnSubmit
				landingBannerSection {
					heading
					hideForm
					formTitle
					formButtonText
					content
					cta {
						target
						title
						url
					}
					background {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				landingTrustedBy {
					trustedByHeading
					selectTrustedByLogos {
						nodes {
							... on WpTrustedByLogo {
								featureImage {
									featureImage {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
				}
				landingTwoColumn {
					flip
					twoColumnContent
					twoColumnHeading
					twoColumnImageBackgroundColor
					twoColumnMedia
					twoColumnVideo
					twoColumnVideoFile {
						node {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					twoColumnVideoYoutube
					twoColumnCta1 {
						target
						title
						url
					}
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				landingTwoColumnImage {
					flip
					twoColumnContent
					twoColumnHeading
					twoColumnImageBackgroundColor
					twoColumnMedia
					twoColumnVideo
					twoColumnVideoFile {
						node {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					twoColumnVideoYoutube
					twoColumnCta1 {
						target
						title
						url
					}
					twoColumnBackgroundImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				landingTwoColumn2 {
					flip
					twoColumnContent
					twoColumnHeading
					twoColumnImageBackgroundColor
					twoColumnMedia
					twoColumnVideo
					twoColumnVideoFile {
						node {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					twoColumnVideoYoutube
					twoColumnCta1 {
						target
						title
						url
					}
					twoColumnContentLogos {
						logo {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				landingOurServices {
					ourServicesHeading
					ourServicesCta {
						target
						title
						url
					}
					backgroundImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 50
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					selectOurServices {
						nodes {
							... on WpService {
								title
								servicesPostFields {
									serviceContent
									serviceCta {
										target
										title
										url
									}
									serviceImage {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
				}
				landingTwoColumn3 {
					flip
					twoColumnContent
					twoColumnHeading
					twoColumnImageBackgroundColor
					twoColumnMedia
					twoColumnVideo
					twoColumnVideoFile {
						node {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					twoColumnVideoYoutube
					twoColumnCta1 {
						target
						title
						url
					}
					twoColumnImage {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
				landingTrustpilotReviews {
					trustpilotHeading
					trustpilotTagline
					trustpilotTitle
					trustpilotCta {
						target
						title
						url
					}
					trustpilotLogo {
						node {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: WEBP
										quality: 80
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
										width: 180
									)
								}
							}
						}
					}
					selectTrustpilotReview {
						nodes {
							... on WpReview {
								title
								date(formatString: "DD MMMM, YYYY")
								reviewPostFields {
									reviewAuthor
									reviewAuthorProfilePicture {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
														width: 56
													)
												}
											}
										}
									}
									reviewQuote
									reviewStarSelect
									reviewAuthorCompanyPicture {
										node {
											altText
											sourceUrl
											localFile {
												childImageSharp {
													gatsbyImageData(
														formats: WEBP
														quality: 80
														transformOptions: { cropFocus: CENTER, fit: COVER }
														layout: CONSTRAINED
														placeholder: BLURRED
														width: 80
													)
												}
											}
										}
									}
									reviewAuthorDesignation
								}
								terms {
									nodes {
										taxonomyName
										name
									}
								}
							}
						}
					}
				}
				landingGetInTouch {
					calendly
					getInTouchFormButtonLabel
					getInTouchFormHeading
					getInTouchHeading
					getInTouchTagline
					getInTouchContent
				}
			}
		}
	}
`;
